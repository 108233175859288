<template>
  <section id="deployment" ref="deploymentContent">
    <PageTitle :title="$t('hospitality.deployment.title')" :breadcrumbs="breadcrumbs"></PageTitle>
    
    <div class="wrapper wrapper-content animated fadeInRight">

      <Header></Header>

      <div class="row" v-if="selectedAgency && (selectedAgency.options.isAgencyDeploymentAllowed || selectedAgency.options.isApartmentsDeployementAllowed)">
        <div class="col">
          <Validation></Validation>
        </div>
      </div>

      <div class="row" v-if="selectedAgency && selectedAgency.options.isAgencyDeploymentAllowed">
        <div class="col">
          <Agency></Agency>
        </div>
      </div>

      <div class="row" v-if="selectedAgency && selectedAgency.options.isApartmentsDeployementAllowed">
        <div class="col">
          <Apartments></Apartments>
        </div>
      </div>

    </div>
  </section>
</template>


<script lang="ts">
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';

import PageTitle from '@root/src/client/components/panels/PageTitle.vue';
import { enableIbox, disableIbox} from '@root/src/client/theme/inspinia-jquery'; 

import { useAgencyAdmin } from '../../composables/useAgencyAdmin';

import Header from '../panels/Header.vue';

import Agency from '../panels/deployment/Agency.vue';
import Apartments from '../panels/deployment/Apartments.vue';
import Validation from '../panels/deployment/Validation.vue';


@Component({
  components: {
    PageTitle,
    Agency,
    Apartments,
    Header,
    Validation
  }
})
export default class Deployment extends mixins(GenericPage) {

  setup(props:any, context:any) {
    const { agencies, selectedAgency } = useAgencyAdmin(props, context);
    return { agencies, selectedAgency }
  }

  get breadcrumbs() {
    return [{
      label: this.$t('home.dashboard')
    },
    {
      label: this.$t('hospitality.title')
    }]
  }

  mounted() {
    enableIbox(this.$refs.deploymentContent as HTMLElement);
  }

  beforeDestroy() {
    disableIbox(this.$refs.deploymentContent as HTMLElement);
  }

}
</script>